import { db } from '../../db';
import Unit from '../../model/unit';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const COLLECTION_NAME = DBConsts.Unit.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allunits";

const state = {
	allunits: new Array<Unit>()
};

const getters = {
	units(): Unit[] {
		return [...state.allunits]
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(Unit.FirestoreDataConverter))
	}),
	addUnit(_: unused, unit: Unit): Promise<firebase.firestore.DocumentReference<Unit>> {
		return db.collection(COLLECTION_NAME).withConverter(Unit.FirestoreDataConverter).add(unit);
	},
	editUnit(_: unused, unit: Unit): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(unit.id).withConverter(Unit.FirestoreDataConverter).set(unit);
	},
	deleteUnit(_: unused, id: string): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(id).delete();
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}