import { db } from '../../db';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";

export const COLLECTION_NAME = DBConsts.Counter.COLLECTION_NAME;
const JOBCOUNT_STOREVARIABLE_NAME = "jobCounter";
const JOB_COLLECTION_NAME = "job";

interface CounterState {
	jobCounter: Counter;
}
interface Counter {
	count: number;
}

const state: CounterState = {
	jobCounter: { count: 0 },
};

const getters = {
	jobCount(): number {
		return state.jobCounter.count
	}
};

const actions = {
	bindJobCounterRef: firestoreAction(context => {
		return context.bindFirestoreRef(JOBCOUNT_STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).doc(JOB_COLLECTION_NAME))
	})
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME,
	JOB_COLLECTION_NAME
}