import { convertCustomObjects } from '../db';
import EJobRoles from '@core-shared/enums/JobRoles';
import IContact from '@core-shared/interfaces/IContact';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Contact implements IContact {
	["id"]!: string;


	name: string = "";
	phone: string = "";
	email: string = "";
	jobRole!: EJobRoles;
	parentID: string = "";
	parentName: string = "";
	parentCollection: string = "";

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(custcont: Contact): firebase.firestore.DocumentData {
			return convertCustomObjects(custcont);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Contact {
			return Object.assign(new Contact(), snapshot.data(options));
		}
	}
}