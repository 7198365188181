import { db } from '../../db';
import { firestoreAction } from 'vuexfire';
import Role from '../../model/role';
import DBConsts from "@core-shared/db.consts";
import { ROLES } from '@/model/role';
import User from '@/model/user';

export const COLLECTION_NAME = DBConsts.Role.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allroles";

const state = {
	allroles: new Array<Role>()
};

const getters = {
	roles(): Role[] {
		return [...state.allroles]
	},
	getRoles: (state: any) => (roleIds: Array<string>): Array<Role> | undefined => {
		const roles = roleIds.map((rid) => state.allroles.find((role: Role) => role.id === rid)).filter((r) => r);
		return roles;
	},

	getRole: (state: any) => (role: Role): Role | undefined => {
		let retRole;
		if (state.allroles) {
			retRole = state.allroles.find((r: Role) => r.id == role.id);
		}

		return retRole;
	},
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(Role.FirestoreDataConverter))
	}),

	async initializeRoles(_: unused): Promise<void> {
		const collection = await db.collection(COLLECTION_NAME).get();
		if (collection.docs.length === 0) {
			ROLES.forEach((r) => {
				db.collection(COLLECTION_NAME).doc(r.id).withConverter(Role.FirestoreDataConverter).set(r);
			});
		}
	},

	addRole(_: unused, role: Role): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(role.id).withConverter(Role.FirestoreDataConverter).set(role);
	},
	editRole(_: unused, role: Role): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(role.id).withConverter(Role.FirestoreDataConverter).set(role);
	},
	deleteRole(_: unused, id: string): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(id).delete();
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}