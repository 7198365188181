import { db } from '@/db';
import ConstructionSchedule from '@/model/constructionSchedule';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";
import 'firebase/compat/firestore';
import { isEmpty } from 'lodash';


export const COLLECTION_NAME = DBConsts.Job.ConstructionSchedule.COLLECTION_NAME;
const STOREVARIABLE_NAME = "constructionSchedule";

const state = {
	constructionSchedules: new Array<ConstructionSchedule>()
};

const getters = {
	getConstructionSchedule: (state: any) => async (customer_id: string, job_id: string) => {
		if (isEmpty(customer_id) || isEmpty(job_id)) {
			return [];
		}
		console.log("GETTING CONSTRUCTION SCHEDULES", customer_id, job_id);
		return await db.collection(DBConsts.Customer.COLLECTION_NAME)
			.doc(customer_id)
			.collection(DBConsts.Job.COLLECTION_NAME)
			.doc(job_id)
			.collection(COLLECTION_NAME)
			.withConverter(ConstructionSchedule.FirestoreDataConverter).orderBy("created", "desc").get()
			.then((querySnapshot) => querySnapshot.docs.map(doc => doc.data()));
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(ConstructionSchedule.FirestoreDataConverter))
	}),
	addConstructionSchedule(_: unused, constructionSchedule: ConstructionSchedule) {
		if (isEmpty(constructionSchedule.customer_id) || isEmpty(constructionSchedule.job_id)) {
			return null;
		}
		return db.collection(DBConsts.Customer.COLLECTION_NAME)
			.doc(constructionSchedule.customer_id)
			.collection(DBConsts.Job.COLLECTION_NAME)
			.doc(constructionSchedule.job_id)
			.collection(COLLECTION_NAME)
			.withConverter(ConstructionSchedule.FirestoreDataConverter)
			.add(constructionSchedule).then(() => {
				db.collection(DBConsts.Customer.COLLECTION_NAME)
					.doc(constructionSchedule.customer_id)
					.collection(DBConsts.Job.COLLECTION_NAME)
					.doc(constructionSchedule.job_id)
					.set({ handoverDate: constructionSchedule.handoverDate }, { merge: true });
			});
	},
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}