
import Vue from "vue";
import Component from "vue-class-component";
import { Getter, Mutation } from "vuex-class";
import { Prop } from "vue-property-decorator";
import User from "../../src/model/user";
import Avatar from "vue-avatar";
import { showGeneratedAvatar, showPhotoAvatar } from "../helpers/index";
import { generateFromString } from "generate-avatar";

@Component({
	components: { Avatar },
})
export default class Appbar extends Vue {
	@Prop() SignOut!: any;
	@Mutation drawer!: any;
	@Getter("getStoreUser") storeUser!: User;

	showGeneratedAvatar = showGeneratedAvatar;
	showPhotoAvatar = showPhotoAvatar;
	generateFromString = generateFromString;

	appVersion = () => {
		return process.env.PACKAGE_VERSION ?? "";
	};

	appHash = () => {
		return process.env.__COMMIT_HASH__ ?? "";
	};

	appHashCopy = () => {
		navigator.clipboard.writeText(this.appHash());
	};
}
