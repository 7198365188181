import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { firestoreAction } from 'vuexfire';
import { db } from '../../db';
import Invoice from '../../model/invoice';
import DBConsts from "@core-shared/db.consts";
import EInvoiceStatusTypes from '@core-shared/enums/InvoiceStatusTypes';

export const COLLECTION_NAME = DBConsts.Invoice.COLLECTION_NAME;
export const DELETED_INVOICES_COLLECTION_NAME = DBConsts.InvoiceDeleted.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allInvoices";

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() - 90);

const state = {
	allInvoices: new Array<Invoice>(),
	receivedDateStart: currentDate.toISOString().split('T')[0],
	receivedDateEnd: new Date().toISOString().split('T')[0],
};

const getters = {
	invoices(): Invoice[] {
		return [...state.allInvoices]
	},
	receivedDateStart: (state: any) => state.receivedDateStart,
	receivedDateEnd: (state: any) => state.receivedDateEnd,
};

const mutations = {
	setReceivedDateStart(state: any, date: string) {
		state.receivedDateStart = date;
	},
	setReceivedDateEnd(state: any, date: string) {
		state.receivedDateEnd = date;
	},
};

const actions = {
	bindRef: firestoreAction(context => {
		const ninetyDaysAgo = new Date();
		ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).where('receivedDate', '>', ninetyDaysAgo.toISOString()).orderBy('receivedDate', 'desc').withConverter(Invoice.FirestoreDataConverter));
	}),

	fetchInvoicesByDateRange: firestoreAction((context, { startDate, endDate }) => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME)
			.where('receivedDate', '>=', startDate.toISOString()) // Convert to ISO string for comparison
			.where('receivedDate', '<=', endDate.toISOString()) // Convert to ISO string for comparison
			.orderBy('receivedDate', 'desc')
			.withConverter(Invoice.FirestoreDataConverter)
		);
	}),
	editInvoice({ }, invoice: Invoice) { // eslint-disable-line
		if (invoice.status && invoice.status === EInvoiceStatusTypes.Deleted) {
			return db.collection(DELETED_INVOICES_COLLECTION_NAME).doc(invoice.id).withConverter(Invoice.FirestoreDataConverter).set(invoice);
		} else {
			return db.collection(COLLECTION_NAME).doc(invoice.id).withConverter(Invoice.FirestoreDataConverter).set(invoice);
		}
	},
	getInvoice: async (store: ({ state: { allInvoices: Invoice[]; } }), id: string): Promise<Invoice | undefined> => {
		let invoice;
		if (state.allInvoices) {
			invoice = state.allInvoices.find((invoice: Invoice) => invoice.id === id);
		}
		if (!invoice) {
			invoice = (await db.collection(COLLECTION_NAME).doc(id).withConverter(Invoice.FirestoreDataConverter).get()).data();
		}

		return invoice;
	},
	async deleteInvoice({ }, invoice: Invoice) { //eslint-disable-line
		await db.collection(COLLECTION_NAME).doc(invoice.id).delete();
		await Promise.all(invoice.attachments.map(attachment => (
			firebase.storage().ref()
				.child(attachment)
				.delete()
		)));
	}
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
	COLLECTION_NAME
}