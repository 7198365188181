import Contact from '@/model/contact';
import Customer from '@/model/customer';
import { firestoreAction } from 'vuexfire';
import { db } from '../../db';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import DBConsts from "@core-shared/db.consts";

export const COLLECTION_NAME = DBConsts.Contact.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allcontacts";

const state = {
	allcontacts: new Array<Contact>()
};

const getters = {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	getContacts: (state: any) => (parentID: string): Contact[] => {
		return state.allcontacts.filter((contact: Contact) => contact.parentID === parentID);
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	getContactsByCustomer: (state: any) => (customer: Customer): Contact[] => {
		if (customer?.id) {
			return state.allcontacts.filter((contact: Contact) => contact.parentID === customer.id);
		}
		return [];
	}
};


const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collectionGroup(COLLECTION_NAME).withConverter(Contact.FirestoreDataConverter));
	}),
	add(_: unused, obj: Contact): Promise<firebase.firestore.DocumentReference<Contact>> {
		return db.collection(obj.parentCollection).doc(obj.parentID).collection(COLLECTION_NAME).withConverter(Contact.FirestoreDataConverter).add(obj);
	},
	edit(_: unused, obj: Contact): Promise<void> {
		return db.collection(obj.parentCollection).doc(obj.parentID).collection(COLLECTION_NAME).doc(obj.id).withConverter(Contact.FirestoreDataConverter).set(obj);
	},
	delete(_: unused, obj: Contact): Promise<void> {
		return db.collection(obj.parentCollection).doc(obj.parentID).collection(COLLECTION_NAME).doc(obj.id).delete();
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}