import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Vendor {
	["id"]!: string;

	procoreId: string = "";
	name: string = "";

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(vendor: Vendor): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return { ...vendor };
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Vendor {
			const data = snapshot.data(options) ?? {};
			return Object.assign(new Vendor(), data);
		}
	}

	static TitleFormatter(vendor: Vendor): string {
		return vendor.name;
	}
}