import { convertCustomObjects } from '@/db';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Role {
	id: string = "";
	name: string = "";
	routes: Array<string> = [];

	constructor(partial?: Partial<Role>) {
		Object.assign(this, partial);
	}

	static FirestoreDataConverter = {
		toFirestore(user: Role): firebase.firestore.DocumentData {
			return convertCustomObjects(user);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Role {
			const data = snapshot.data(options) ?? {};
			return Object.assign(new Role(), data);
		}
	}
}

export enum ROLE_IDS {
	default = "default",
	supervisor = "supervisor",
	constructionManager = "constructionManager",
	pm = "pm",
	estimator = "estimator",
	admin = "admin",
	administration = "administration",
	accounts = "accounts",
}



export const ROLES = [
	new Role({ id: ROLE_IDS.default, name: 'Default', routes: [] }),
	new Role({ id: ROLE_IDS.supervisor, name: 'Supervisor', routes: ['all'], }),
	new Role({ id: ROLE_IDS.constructionManager, name: 'Construction Manager', routes: ['all'] }),
	new Role({ id: ROLE_IDS.pm, name: 'Project Manager', routes: ['all'] }),
	new Role({ id: ROLE_IDS.estimator, name: 'Estimator', routes: ['all'] }),
	new Role({ id: ROLE_IDS.admin, name: 'Super Admin', routes: ['all'] }),
	new Role({ id: ROLE_IDS.administration, name: 'Administration', routes: ['all'] }),
	new Role({ id: ROLE_IDS.accounts, name: 'Accounts', routes: ['all'] }),
]