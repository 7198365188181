import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
export default class Category {
	["id"]!: string;

	title = "";

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(category: Category): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return { ...category };
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Category {
			const data = snapshot.data(options)!;
			return Object.assign(new Category(), data);
		}
	}
}