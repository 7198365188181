import { db } from '../../db';
import InvoiceCount from '../../model/invoiceCount';
import EInvoiceCountDoc from '@core-shared/enums/InvoiceCountDoc';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";
import 'firebase/compat/firestore';

export const COLLECTION_NAME = DBConsts.InvoiceCount.COLLECTION_NAME;
const STOREVARIABLE_NAME = "invoiceCount";

const state = {
	invoiceCount: new Array<InvoiceCount>()
};

const getters = {
	invoiceCountFromDB(): InvoiceCount {
		return state.invoiceCount.find(item => item.id === EInvoiceCountDoc.Count) || new InvoiceCount();
	},
	invoiceIncludingSoftDeletedCountFromDB(): InvoiceCount {
		return state.invoiceCount.find(item => item.id === EInvoiceCountDoc.CountIncludingSoftDeleted) || new InvoiceCount();
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(InvoiceCount.FirestoreDataConverter))
	}),
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}