import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export interface BudgetedCostCode {
	id: string;
	full_code: string;
	fullCode: string;
	flat_code: string;
	flat_name: string;
}

export default class CostCode {
	["id"]!: string;

	procoreId: string = "";
	parentId!: string;
	name: string = "";
	code: string = "";
	fullCode: string = "";

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(costCode: CostCode): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return { ...costCode };
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot, // eslint-disable-line
			options: firebase.firestore.SnapshotOptions // eslint-disable-line
		): CostCode {
			const data = snapshot.data(options)!;
			return Object.assign(new CostCode(), data);
		}
	}

	static TitleFormatter(costCode: CostCode, costCodeList: CostCode[]): string {
		let title = costCode.fullCode || costCode.name || "";

		if (costCode.fullCode && costCode.name) {
			title = `${costCode.fullCode} - ${costCode.name}`;
		}
		
		return title;
	}
}

//Costcodes need additional fields to be displayed with title on the dropdown in Prices page 
export interface IExtendedCostCodes extends CostCode {
	group?: string,
	header?: string
}