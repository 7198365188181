import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { firestoreAction } from 'vuexfire';
import { db } from '../../db';
import Invoice from '../../model/invoice';
import DBConsts from "@core-shared/db.consts";

export const COLLECTION_NAME = DBConsts.InvoiceDeleted.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allInvoicesDeleted";

const state = {
	allInvoicesDeleted: new Array<Invoice>()
};

const getters = {
	invoicesDeleted(): Invoice[] {
		return [...state.allInvoicesDeleted]
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).orderBy('invoiceDate', 'desc').withConverter(Invoice.FirestoreDataConverter))
	}),
	editInvoice({ }, invoice: Invoice) { // eslint-disable-line
		return db.collection(COLLECTION_NAME).doc(invoice.id).withConverter(Invoice.FirestoreDataConverter).set(invoice);
	},
	getInvoice: async (store: ({ state: { allInvoicesDeleted: Invoice[]; } }), id: string): Promise<Invoice | undefined> => {
		let invoice;
		if (state.allInvoicesDeleted) {
			invoice = state.allInvoicesDeleted.find((invoice: Invoice) => invoice.id === id);
		}
		if (!invoice) {
			invoice = (await db.collection(COLLECTION_NAME).doc(id).withConverter(Invoice.FirestoreDataConverter).get()).data();
		}

		return invoice;
	},
	async deleteInvoice({ }, invoice: Invoice) { //eslint-disable-line
		await db.collection(COLLECTION_NAME).doc(invoice.id).delete();
		await Promise.all(invoice.attachments.map(attachment => (
			firebase.storage().ref()
				.child(attachment)
				.delete()
		)));
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}