import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
export default class InvoiceCount {
	["id"]!: string;
	totalInvoicesCount!:number;
	invoicesToBeEnteredCount!:number;
	invoicesToBeReconciledCount!:number;
	invoicesToBeProcessedCount!:number;
	invoicesToBeApprovedCount!:number;
	invoicesDeniedCount!:number;

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(invoiceCount: InvoiceCount): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return { ...invoiceCount };
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): InvoiceCount {
			const data = snapshot.data(options)!;
			return Object.assign(new InvoiceCount(), data);
		}
	}
}