import { db } from '../../db';
import Category from '../../model/category';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const COLLECTION_NAME = DBConsts.Category.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allcategories";

const state = {
	allcategories: new Array<Category>()
};

const getters = {
	categories(): Category[] {
		return [...state.allcategories];
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(Category.FirestoreDataConverter))
	}),
	addCategory(_: unused, category: Category): Promise<firebase.firestore.DocumentReference<Category>> {
		return db.collection(COLLECTION_NAME).withConverter(Category.FirestoreDataConverter).add(category);
	},
	editCategory(_: unused, category: Category): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(category.id).withConverter(Category.FirestoreDataConverter).set(category);
	},
	deleteCategory(_: unused, id: string): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(id).delete();
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}