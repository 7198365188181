import { convertCustomObjects } from '../db';
import Contact from './contact';
import ISite from '@core-shared/interfaces/ISite';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Site implements ISite {
	["id"]!: string;

	name: string = "";
	address: string = "";
	notes: string = "";
	specialConditions: string = "";

	primaryContact!: Contact;
	billingContact!: Contact;

	parentID: string = "";
	parentName: string = "";
	parentCollection: string = "";

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: true, // i want to save the id when saving a contact to another record
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(obj: Site): firebase.firestore.DocumentData {
			return convertCustomObjects(obj);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Site {
			return Object.assign(new Site(), snapshot.data(options));
		}
	}
}