import IJob from "@core-shared/interfaces/IJob";

/**
 * nameof()
 * Usage: nameof<Job>("name");
 * @param name 
 * @returns string
 */
export const nameof = <T>(name: keyof T): string => name.toString();

/**
 * PickTextColorBasedOnBgColorAdvanced()
 * Cant remember where I grabbed this from, probably a random stackoverflow (possibly https://stackoverflow.com/a/41491220)
 * It was first used in the Collins Constructions Quoting Calculator. 
 * Has proven to be quite useful.
 * Gets used a lot with RandomColor (https://github.com/davidmerfield/randomColor/) eg: randomColor({ seed: params.value })
 * 
 * eg: PickTextColorBasedOnBgColorAdvanced(bgColor, "#FFFFFF", "#000000")
 * 
 * 
 * simple version from above stackoverflow
 * 
 *	function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
 *		var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
 *		var r = parseInt(color.substring(0, 2), 16); // hexToR
 *		var g = parseInt(color.substring(2, 4), 16); // hexToG
 *		var b = parseInt(color.substring(4, 6), 16); // hexToB
 *		return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
 *		darkColor : lightColor;
 *	}
 *
 * @param bgColor 
 * @param lightColor 
 * @param darkColor 
 * @returns 
 */
export function PickTextColorBasedOnBgColorAdvanced(bgColor: string, lightColor: string, darkColor: string) {
	const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
	const r = parseInt(color.substring(0, 2), 16); // hexToR
	const g = parseInt(color.substring(2, 4), 16); // hexToG
	const b = parseInt(color.substring(4, 6), 16); // hexToB
	const uicolors = [r / 255, g / 255, b / 255];
	const c = uicolors.map((col) => {
		if (col <= 0.03928) {
			return col / 12.92;
		}
		return Math.pow((col + 0.055) / 1.055, 2.4);
	});
	const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
	return L > 0.179 ? darkColor : lightColor;
}


/**
 * Format the titke of the given Job
 * @param{Ijob} 'job' The job to be formatted
 * @return{string} formatted title
 * */
export function JobTitleFormatter(job: IJob): string {
	if (!job) {
		return "";
	}
	const customeridentifiers: string[] = [];
	const title = [];
	const ret = [];

	job.customerIdentifiers.forEach(identifier => {
		if (identifier.value) {
			customeridentifiers.push(`${identifier.name} ${identifier.value}`);
		}
	});

	if (job.number) {
		title.push(job.number)
		title.push(" - ")
	}
	if (job.customer?.shortName || job.customer?.name || job.site?.name) {
		title.push("[")
		if (job.customer?.shortName) {
			title.push(job.customer.shortName);
			title.push(" » ")
		} else if (job.customer?.name) {
			title.push(job.customer.name);
			title.push(" » ")
		}
		if (job.site?.name) {
			title.push(job.site.name)
		}
		title.push("] ")
	}

	if (job.name) {
		title.push(job.name)
	}

	ret.push(title.join(""))
	if (customeridentifiers.length) {
		ret.push(customeridentifiers.join(" ❖ "))
	}
	return ret.join(" ⭆ ");
}