
import { Component, Model, Prop } from "vue-property-decorator";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import User from "@/model/user";

import Vue from "vue";

@Component
export default class Login extends Vue {
	@Model("input") loadingModel!: boolean;

	showPassword: boolean = false;
	email: string = "";
	password: string = "";

	get loading(): boolean {
		return this.loadingModel;
	}
	set loading(value: boolean) {
		this.$emit("input", value);
	}

	signInWithGoogle(): void {
		this.loading = true;
		const provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.catch((error) => console.error(error));
	}

	async signInWithPassword(): Promise<void> {
		this.loading = true;
		await firebase
			.auth()
			.signInWithEmailAndPassword(this.email.trim(), this.password)
			.catch((error) => {
				console.error(error);
				this.$vToastify.error(`Login credentials not recognized.`);
				this.loading = false;
			});
	}
}
