import { convertCustomObjects } from '@/db';
import { Rating } from '@core-shared/interfaces';
import Contact from './contact';
import IContact from '@core-shared/interfaces/IContact';
import ICustomer from '@core-shared/interfaces/ICustomer';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Customer implements ICustomer {
	["id"]!: string;

	name: string = "";
	rating: number = 0;
	ratings: Rating[] = [];
	jobNumberCode: string = "";

	billingAddress: string = "";
	billingContact!: Contact;

	notes: string = "";
	specialConditions: string = "";

	constructor(partial?: Partial<Customer>) {
		Object.assign(this, partial);
	}
	shortName: string = "";
	address: string = "";
	primaryContact!: IContact;

	static FirestoreDataConverter = {
		toFirestore(customer: Customer): firebase.firestore.DocumentData {
			return convertCustomObjects(customer);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Customer {
			const data = snapshot.data(options)!;
			return Object.assign(new Customer(), data);
		}
	}
}