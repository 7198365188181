import { db, firebaseConfig } from '../../db';
import { firestoreAction } from 'vuexfire';
import User, { NewUser, USER_SOURCES } from '../../model/user';
import DBConsts from "@core-shared/db.consts";
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/auth';

export const COLLECTION_NAME = DBConsts.User.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allusers";

const state = {
	allusers: new Array<User>()
};

const getters = {
	users(): User[] {
		return state.allusers.map((u) => new User(undefined, u))
	},

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
	getUser: (state: any) => async (userid: string): Promise<User | undefined> => {
		let user;
		if (state.allusers) {
			user = new User(undefined, state.allusers.find((user: User) => user.id == userid));
		}

		return user;
	},
	getUserByIdWithoutStore: (state: any) => async (userid: string): Promise<User | undefined> => {
		return (await db.collection(COLLECTION_NAME).withConverter(User.FirestoreDataConverter).doc(userid).get()).data();
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(User.FirestoreDataConverter))
	}),
	async addUser(_: unused, user: NewUser): Promise<void> {
		const authApp = firebase.initializeApp(firebaseConfig, 'authApp');

		// const providers = await authApp.auth().fetchSignInMethodsForEmail(user.email);
		user.id = (await authApp.auth().createUserWithEmailAndPassword(user.email, user.password)).user?.uid || '';
		user.source = USER_SOURCES.password;
		db.collection(COLLECTION_NAME).doc(user.id).withConverter(User.FirestoreDataConverter).set(user);

	},
	resetPassword(_: unused, user: User): Promise<void> {
		return firebase.auth().sendPasswordResetEmail(user.email)
	},

	editUser(_: unused, user: User): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(user.id).withConverter(User.FirestoreDataConverter).set(user);
	},
	async deleteUser(_: unused, id: string): Promise<void> {
		//this will throw an error if db permissions are incorrect
		await db.collection(COLLECTION_NAME).doc(id).delete();

		const deleteUserAuthentication = firebase
			.app()
			.functions("australia-southeast1")
			.httpsCallable("deleteUser");
		await deleteUserAuthentication({ userid: id });
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}