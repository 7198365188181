export default class DBConsts {
	static readonly MAX_BATCH_OPPERATIONS = 500;

	static readonly Business = {
		COLLECTION_NAME: 'businesses'
	}

	static readonly BudgetCode = {
		COLLECTION_NAME: 'budgetcodes'
	}

	static readonly Category = {
		COLLECTION_NAME: 'categories'
	}

	static readonly CostCode = {
		COLLECTION_NAME: 'costcodes'
	}

	static readonly CostCodeSegmentItems = {
		COLLECTION_NAME: 'costcodesegmentitems'
	}

	static readonly Counter = {
		COLLECTION_NAME: 'counters'
	}

	static readonly Customer = {
		COLLECTION_NAME: 'customers'
	}

	static readonly Invoice = {
		COLLECTION_NAME: 'invoices'
	}

	static readonly InvoiceDeleted = {
		COLLECTION_NAME: 'invoices_deleted'
	}

	static readonly Job = {
		COLLECTION_NAME: 'jobs',
		ConstructionSchedule: {
			COLLECTION_NAME: 'construction_schedule'
		}
	}

	static readonly Price = {
		COLLECTION_NAME: 'prices'
	}

	static readonly Task = {
		COLLECTION_NAME: 'tasks'
	}

	static readonly Unit = {
		COLLECTION_NAME: 'units'
	}

	static readonly User = {
		COLLECTION_NAME: 'users'
	}

	static readonly Vendor = {
		COLLECTION_NAME: 'vendors'
	}

	static readonly ProcoreUser = {
		COLLECTION_NAME: "procoreusers"
	}

	static readonly Role = {
		COLLECTION_NAME: "roles"
	}

	static readonly Contact = {
		COLLECTION_NAME: "contacts"
	}

	static readonly Site = {
		COLLECTION_NAME: "sites"
	}

	static readonly AppSettings = {
		COLLECTION_NAME: "appsettings"
	}

	static readonly Quotation = {
		COLLECTION_NAME: "quotations"
	}

	static readonly InvoiceCount = {
		COLLECTION_NAME: DBConsts.Counter.COLLECTION_NAME
	}

	static readonly ExternalActions = {
		COLLECTION_NAME: "externalactions"
	}

	static readonly BuildPass = {
		Subcontractors: {
			InvitedStatus: "INVITED",
			LAST_SYNC: "LastSync",
			COLLECTION_NAME: "buildpass_subcontractors",
			Tags: {
				Archived: "Archived",
				Duplicate: "Duplicate"
			}
		}
	}

	static readonly Procore = {
		Vendors: {
			LAST_SYNC: "0_LastSync",
			COLLECTION_NAME: "procore_vendors"
		}
	}

	static readonly SubcontractorCompliance = {
		COLLECTION_NAME: "subcontractor_compliance"
	}

	static readonly Timecards = {
		COLLECTION_NAME: "timecards"
	}
}
