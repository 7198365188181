import { db } from '../../db';
import Customer from '../../model/customer';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const COLLECTION_NAME = DBConsts.Customer.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allcustomers";

const state = {
	allcustomers: new Array<Customer>()
};

const getters = {
	customers(): Customer[] {
		return [...state.allcustomers]
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).orderBy("name", "asc").withConverter(Customer.FirestoreDataConverter))
	}),
	addCustomer(_: unused, customer: Customer): Promise<firebase.firestore.DocumentReference<Customer>> {
		return db.collection(COLLECTION_NAME).withConverter(Customer.FirestoreDataConverter).add(customer);
	},
	editCustomer(_: unused, customer: Customer): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(customer.id).withConverter(Customer.FirestoreDataConverter).set(customer);
	},
	deleteCustomer(_: unused, customer: Customer): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(customer.id).delete();
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}