
import User from "@/model/user";
import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import router from "@/router";
import { RouteConfig } from "vue-router";

@Component
export default class Settings extends Vue {
	@Getter drawer!: boolean;
	@Getter("menuItems") items!: Array<any>;
	@Getter("getStoreUser") storeUser!: User;

	get allowedRoutes(): RouteConfig[] | undefined {
		return router?.options?.routes?.filter((pr) => {
			if (pr.name === "Home") {
				return true;
			}
			return this.storeUser.isRouteAllowed(pr.path.split("/")[1]);
		});
	}

	get filteredItems(): Array<any> {
		const filtered = this.items.filter((item) => {
			if (item.divider) {
				return true;
			} else if (item.children) {
				item.children = item.children.filter((ci: any) => this.allowedRoutes?.find((ar) => ci.to?.name === ar.name));
				return item.children.length > 0;
			} else {
				return this.allowedRoutes?.find((ar) => item.to?.name === ar.name);
			}
		});

		return filtered;
	}

	badgeContent(badge: string): string {
		// Used to display the contents of badge (like number of notifications remaining to be seen)
		return "";
	}
}
