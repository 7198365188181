import { db, fbIncrementOne } from "../../db";
import Job from "../../model/job";
import Customer from "../../store/modules/customer";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { firestoreAction } from "vuexfire";
import DBConsts from "@core-shared/db.consts";
import { extractSurveyFilesUploads } from "@/helpers";

export const COLLECTION_NAME = DBConsts.Job.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allJobs";

const state = {
	allJobs: new Array<Job>(),
};

const getters = {
	jobs(): Job[] {
		return [...state.allJobs];
	},
	sortedJobs(): Job[] {
		return [
			...state.allJobs.sort((a, b) => (a.number > b.number ? 1 : -1)),
		];
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	getJob: (state: any) => async (customerid: string, jobid: string): Promise<Job | undefined> => {
		let job;
		if (state.allJobs) {
			job = state.allJobs.find((job: Job) => job.id == jobid);
		}
		if (!job) {
			job = (await db.collection(Customer.COLLECTION_NAME).doc(customerid).collection(COLLECTION_NAME).doc(jobid).withConverter(Job.FirestoreDataConverter).get()).data();
		}

		return job;
	},
};

const actions = {
	bindRef: firestoreAction((context) => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collectionGroup(COLLECTION_NAME).withConverter(Job.FirestoreDataConverter));
	}),
	addJob(_: unused, job: Job): Promise<firebase.firestore.DocumentReference<Job>> {
		return extractSurveyFilesUploads(job, "surveyData", "surveyTemplate", "surveyFiles").then((job) => {
			return db.collection("counters")
				.doc("job")
				.update({ count: fbIncrementOne })
				.then(() => {
					job.number = job.number?.toString().toUpperCase();
					return db.collection(Customer.COLLECTION_NAME).doc(job.customerID).collection(COLLECTION_NAME).withConverter(Job.FirestoreDataConverter).add(job);
				});
		});


	},
	editJob(_: unused, job: Job): Promise<void> {
		return db.collection(Customer.COLLECTION_NAME).doc(job.customerID).collection(COLLECTION_NAME).doc(job.id).withConverter(Job.FirestoreDataConverter).set(job);
	},
	deleteJob(_: unused, job: Job): Promise<void> {
		return db.collection(Customer.COLLECTION_NAME).doc(job.customerID).collection(COLLECTION_NAME).doc(job.id).delete();
	},
};

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
};
