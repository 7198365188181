import Customer from '@/model/customer';
import Site from '@/model/site';
import { firestoreAction } from 'vuexfire';
import { db } from '../../db';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import DBConsts from "@core-shared/db.consts";

export const COLLECTION_NAME = DBConsts.Site.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allsites";

const state = {
	allsites: new Array<Site>()
};

const getters = {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	getSites: (state: any) => (parentID: string): Site[] => {
		return state.allsites.filter((site: Site) => site.parentID === parentID);
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	getSitesByCustomer: (state: any) => (obj: Customer): Site[] => {
		if (obj?.id) {
			return state.allsites.filter((site: Site) => site.parentID === obj.id);
		}
		return [];
	},
	getCustomerSitesOrAll: (state: any) => (customer: Customer | undefined): Site[] => {
		if (customer?.id) {
			return state.allsites.filter((site: Site) => site.parentID === customer.id);
		} else {
			return state.allsites;
		}
	}
};


const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collectionGroup(COLLECTION_NAME).withConverter(Site.FirestoreDataConverter));
	}),
	add(_: unused, obj: Site): Promise<firebase.firestore.DocumentReference<Site>> {
		return db.collection(obj.parentCollection).doc(obj.parentID).collection(COLLECTION_NAME).withConverter(Site.FirestoreDataConverter).add(obj);
	},
	edit(_: unused, obj: Site): Promise<void> {
		return db.collection(obj.parentCollection).doc(obj.parentID).collection(COLLECTION_NAME).doc(obj.id).withConverter(Site.FirestoreDataConverter).set(obj);
	},
	delete(_: unused, obj: Site): Promise<void> {
		return db.collection(obj.parentCollection).doc(obj.parentID).collection(COLLECTION_NAME).doc(obj.id).delete();
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}