import "../node_modules/ag-grid-enterprise/dist/styles/ag-grid.scss";
import "../node_modules/ag-grid-enterprise/dist/styles/ag-theme-alpine/sass/ag-theme-alpine.scss";
import { LicenseManager } from "ag-grid-enterprise";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import UUID from "vue-uuid";
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import VuetifyConfirm from 'vuetify-confirm'
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import VueToastify from "vue-toastify";
import "./assets/styles/main.css";
import { setLicenseKey } from "survey-core";

setLicenseKey(
	"NTVlMWFiNDctZWZiOC00YjcyLThmM2YtMzI4MTUwZjAwZTZiOzE9MjAyNC0wOC0wMywyPTIwMjQtMDgtMDMsND0yMDI0LTA4LTAz"
);

Vue.use(VueToastify);
Vue.use(VuetifyConfirm, { vuetify })
// @ts-ignore
Vue.use(UUID);
Vue.use(VuetifyGoogleAutocomplete, {
	apiKey: 'AIzaSyC4qSa2Ug2ow8JoVw6sFk6gRJBKwU6yt_E',
});

//ag-grid License
LicenseManager.setLicenseKey("CompanyName=Collins Constructions FNQ,LicensedApplication=CCFNQ Core,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-010348,ExpiryDate=10_September_2021_[v2]_MTYzMTIyODQwMDAwMA==73066208375fd907ad02ebd0ef11182b");

Vue.config.productionTip = false

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')