import StoredUser from "@core-shared/interfaces/IStoredUser";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Comment from "./comment"
import ERequisitionApprovalTypes from "@core-shared/enums/RequisitionApprovalTypes";

export interface Commitment {
	id: string;
	number: string;
	vendor: {
		company: string;
	}
	grand_total: number;
	company: string;
	title: string;
	status: string;
	line_items: CommitmentLineItem[];
}

export interface InvoiceLineItem {
	uuid: string;
	priceId?: string;
	quantity?: number | string;
	amount?: number | string;
	unitCost?: number;
	unit?: string;
	discount?: string | number;
	description?: string;
	directCostLineItemId?: string;
	costCode?: string;
	fullCode?: string;
	costCodeId?: string;
	wbsCodeId?: string;
	lineItemType?: string;
	supplierCode?: string;
}

export interface CommitmentLineItem {
	id: number;
	amount?: number;
	grand_total?: number;
	title?: string;
	description: string;
	claimedAmount?: number;
	costCode: string;
	priceId?: string;
	balance_to_finish?: number;
}

export default class Invoice {
	["id"]!: string;

	invoiceNumber: string = "";
	jobNumber: string = "";
	supplierName: string = "";
	invoiceDate: string = new Date().toISOString().substr(0, 10);
	attachments: string[] = [];
	directCostId: string = "";
	requisitionId: string = "";
	commitmentId: string = "";
	commitmentNumber?: string;
	businessId: string = "";
	lineItems: InvoiceLineItem[] = [];
	commitmentLineItems: CommitmentLineItem[] = [];
	description: string = "";
	paymentDate: string = "";
	startDate: string = "";
	endDate: string = "";
	receivedDate: string = "";
	directCostType: string = "invoice";
	approved: boolean = false;
	approvedDate: string | undefined;
	approvedBy: StoredUser | undefined;
	directCostCreatedDate: string | undefined;
	directCostCreatedBy: StoredUser | undefined;
	requisitionCreatedDate: string | undefined;
	requisitionCreatedBy: StoredUser | undefined;
	requisitionApprovalComments: Comment[] = [];
	requisitionApprovalStatus?: ERequisitionApprovalTypes;
	requisitionApprovalModifiedBy?: StoredUser | undefined;
	requisitionApprovalModifiedDate?: firebase.firestore.Timestamp | undefined;
	notes: string = "";
	netAmount: string = "";
	error: string = "";
	fromEmail: string = "";
	emailMessage: string = "";
	emailSubject: string = "";
	allAttachments: string[] = [];
	activeAttachment: string = "";
	commitmentAccounting: string = '';
	status: string = '';
	deletedAt?: firebase.firestore.Timestamp | undefined;
	selectedTab: string = ""
	static DIRECT_COST_TYPES = ['invoice', 'expense', 'payroll'];

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static entered(invoice: Invoice): boolean {
		return !!(invoice.directCostId || invoice.requisitionId);
	}

	static enteredText(invoice: Invoice): string {
		const directCost = !!invoice.directCostId;
		const commitmentClaim = !!invoice.requisitionId;
		let text = directCost ? 'Direct Cost ' : '';
		text = text + (commitmentClaim ? ' Commitment Claim' : '');
		return text.trim();
	}

	static FirestoreDataConverter = {
		toFirestore(invoice: Invoice): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return { ...invoice };
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Invoice {
			const data = snapshot.data(options)!;
			const id = snapshot.id;
			return Object.assign(new Invoice(), { id, ...data });
		}
	}

	static TitleFormatter(invoice: Invoice): string {
		return invoice.invoiceNumber;
	}
}