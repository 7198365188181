import ESurveyTypes from '@core-shared/enums/SurveyTypes';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/home',
		name: "Home", component: () => import("@/views/Home.vue")
	},
	{
		path: '/settings',
		name: 'Settings',
		component: () => import("@/views/Settings.vue")
	},
	{
		path: '/businesses',
		name: 'Businesses',
		component: () => import("@/views/Businesses.vue")
	},
	{
		path: '/jobs',
		name: 'Jobs',
		component: () => import("@/views/Jobs.vue")
	},
	{
		path: '/subcontractors',
		name: 'Subcontractors',
		component: () => import("@/views/Subcontractors.vue")
	},
	{
		path: '/timesheets',
		name: 'Timesheets',
		component: () => import("@/views/Timesheets.vue")
	},
	{
		path: '/jobs/add/:customerid?',
		name: 'AddJob',
		component: () => import("@/views/Job/AddEdit.vue"),
		props: route => {
			return {
				customerid: route.params.customerid,
				add: true
			}
		}
	},
	{
		path: '/jobs/edit/:customerid/:jobid',
		name: 'EditJob',
		component: () => import("@/views/Job/AddEdit.vue"),
		props: true
	},
	{
		path: '/job/:customerid/:jobid',
		name: 'Job',
		component: () => import("@/views/Job/View.vue"),
		props: true
	},
	{
		path: '/invoices/:id',
		name: 'Invoice',
		component: () => import("@/views/Invoice.vue")
	},
	{
		path: '/invoices',
		name: 'Invoices',
		component: () => import("@/views/Invoices.vue")
	},
	{
		path: '/customers',
		name: 'Customers',
		component: () => import("@/views/Customers.vue")
	},
	{
		path: '/central-register',
		name: 'CentralRegister',
		component: () => import("@/views/CentralRegister.vue")
	},
	{
		path: '/cc-flowchart',
		name: 'CCFlowchart',
		component: () => import("@/views/CCFlowchart.vue")
	},
	{
		path: '/variation-request',
		name: 'VariationRequest',
		component: () => import("@/views/VariationRequest.vue")
	},
	{
		path: '/cp-flowchart',
		name: 'CPFlowchart',
		component: () => import("@/views/CPFlowchart.vue")
	},
	{
		path: '/followupfriday',
		name: 'FollowupFriday',
		component: () => import("@/views/FollowupFriday.vue")
	},
	{
		path: "/labour",
		name: "Labour",
		component: () => import("@/views/LabourPlanner.vue")
	},
	{
		path: "/supplierQuotes",
		name: "Supplier Quotes",
		component: () => import("@/views/SupplierQuotes.vue")
	},
	{
		path: "/surveyCreator",
		name: "Survey Creator",
		component: () => import("@/views/SurveyCreatorComponent.vue"),
		props: route => {
			const surveyType = route.params.surveyType;
			const isValidSurveyType = Object.values(ESurveyTypes).includes(surveyType as ESurveyTypes);
			return {
				surveyType: isValidSurveyType ? surveyType : null,
			};
		}
	},
	{
		path: '*',
		redirect: { name: "Home" }
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
