import store from '../store';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { ContactDetails, Rating } from '@core-shared/interfaces';
import { convertCustomObjects } from '@/db';
import User from './user';

interface TradeAccount {
	active: boolean;
	id: string;
	limit: number;
	limitFrequency: string;
	password: string;
}

interface Bank {
	accountName: string;
	accountNumber: string;
	bsb: string;
	name: string;
}

interface Entity {
	abn: string;
	acn: string;
	contractor: boolean;
	mrcat: string;
	paymentTerms: string;
	qbcc: string;
	supplier: boolean;
	tfn: string;
	tradeAgreement: string;
}

export default class Business {
	["id"]!: string;

	addressBusinessRegistered: string = "";
	addressTrading: string = "";
	approvalNotes: string = "";
	approved: boolean = false;
	archived: boolean = false;
	bank: Bank = { accountName: "", accountNumber: "", name: "", bsb: "" };
	categories: string[] = [];
	contact: ContactDetails = { name: "", email: "", number: "", address: "" }
	contacts: ContactDetails[] = [];
	details: Entity = { abn: "", acn: "", tfn: "", qbcc: "", mrcat: "", contractor: false, supplier: false, paymentTerms: "", tradeAgreement: "" };
	name: string = "";
	notes: string = "";
	primaryContact: ContactDetails = { name: "", email: "", number: "", address: "" }
	ratings: Rating[] = []
	rating: number = 0;
	tradeAccount: TradeAccount = { active: false, id: "", password: "", limit: 0, limitFrequency: "" }
	vendorId: string = '';
	createdBy: User = store.state.storeUser

	constructor(name?: string) {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
		this.name = name ? name : "";
	}

	static FirestoreDataConverter = {
		toFirestore(business: Business): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here

			//ensure that account.limit is a number/dollar value
			business.tradeAccount.limit = Number(business.tradeAccount.limit.toString().replace(/[^0-9.]+/g, ''));
			// // eslint-disable-next-line @typescript-eslint/no-explicit-any
			// business.contacts = business.contacts.map((contact: ContactDetails) => ((obj: any) => {
			// 	for (const k in obj) if (obj[k] === undefined) delete obj[k];
			// 	return obj;
			// })(contact))


			// Ratings
			// I should probably store these in their own collection, but there is little need at the moment due to size of application and
			// usage, i am trading off calculating the rating each save with the complexity of implementing the ratings system as a seperate collection
			// and gathering the data when required etc.
			const count: number[] = [];
			business.ratings.forEach((rating: Rating) => {
				if (rating.rating in count) {
					count[rating.rating]++;
				} else {
					count[rating.rating] = 1;
				}
			});

			let workingWeightedAverage = 0;
			count.forEach((val, indx) => {
				workingWeightedAverage += indx * val;
			});

			business.rating = (workingWeightedAverage / business.ratings.length) || 0;

			return convertCustomObjects(business);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Business {
			const data = snapshot.data(options)!;
			return Object.assign(new Business(), data);
		}
	}

	nonBindShallow(): Business {
		const ret = Object.assign(
			new Business(),
			this
		);
		ret.id = this.id;
		return ret;
	}
}