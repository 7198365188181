import { db } from '../../db';
import CostCode from '../../model/costcode';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";
import { IExtendedCostCodes } from '../../model/costcode'

export const COLLECTION_NAME = DBConsts.CostCode.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allCostCodes";

const state = {
	allCostCodes: new Array<CostCode>()
};

const getters = {
	costcodes(): CostCode[] {
		return [...state.allCostCodes].sort((a, b) => (a.fullCode > b.fullCode) ? 1 : ((b.fullCode > a.fullCode) ? -1 : 0));
	},
	extendedCostCodes(): IExtendedCostCodes[] {
		let extendedCostCodes: IExtendedCostCodes[] = [...state.allCostCodes];
		let parents = extendedCostCodes.filter((ecc) => ecc.parentId == null);
		let children = extendedCostCodes.filter((ecc) => ecc.parentId);
		parents = parents.map((parent) => {
			parent.header = `${parent?.fullCode} - ${parent?.name}`
			return parent
		})
		children = children.map((child) => {
			const parent = parents.find((parent) => parent.id == child.parentId)
			child.group = `${parent?.fullCode} - ${parent?.name}`
			return child
		})
		extendedCostCodes = [...parents, ...children].sort((a, b) => (a.fullCode > b.fullCode) ? 1 : ((b.fullCode > a.fullCode) ? -1 : 0))
		return extendedCostCodes
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(CostCode.FirestoreDataConverter))
	})
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}