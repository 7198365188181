import { db, Timestamp } from '../../db';
import AppSettings from '../../model/appSettings';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const COLLECTION_NAME = DBConsts.AppSettings.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allappsettings";

const state = {
	allappsettings: new Array<AppSettings>()
};

const getters = {
	allAppSettings(): AppSettings[] {
		return [...state.allappsettings];
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(AppSettings.FirestoreDataConverter))
	}),
	addAppSettings(_: unused, appSettings: AppSettings): Promise<firebase.firestore.DocumentReference<AppSettings>> {
		return db.collection(COLLECTION_NAME).withConverter(AppSettings.FirestoreDataConverter).add(appSettings);
	},
	editAppSettings(_: unused, appSettings: AppSettings): Promise<void> {
		appSettings.lastUpdated = Timestamp.now();
		return db.collection(COLLECTION_NAME).doc(appSettings.id).withConverter(AppSettings.FirestoreDataConverter).set(appSettings);
	},
	deleteAppSettings(_: unused, id: string): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(id).delete();
	}
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}