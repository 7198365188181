// export function nonBindShallow<T>(obj: T): T {
//     const ret = Object.assign(
//         Factory.create(T),
//         obj
//     );
//     //ret.id = obj.id;
//     return ret;
// }

import Job from "@/model/job";
import User from "@/model/user";
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import {surveyFilesStorageBucket} from '../db'
// could probably be simplified to something like
// export function nonBindShallow(obj: any) {
//     const ret = JSON.parse(JSON.stringify(obj));
//     ret.id = obj.id;
//     return ret;
// }

export class Factory {
	static create<T>(type: new () => T): T {
		return new type();
	}
}

// Create our number formatter.
const currencyFormatter = new Intl.NumberFormat("en-AU", {
	style: "currency",
	currency: "AUD",
});

function showGeneratedAvatar(user: User|undefined):boolean{
	if (!user) return false;
	if(user.overrideDefaultAvatar){
		if(user.overrideAvatarOption.useGeneratedAvatar){
			return true;
		}else if(user.overrideAvatarOption.usePhotoUrl){
			if(!user.photoURL && !user.displayName){
				return true;
			}
		}else if (user.overrideAvatarOption.useInitials && !user.displayName){
			return true;
		}
	}else if (!user.photoURL && !user.displayName){
		return true;
	}
	return false;
}

function showPhotoAvatar(user: User|undefined):boolean{
	if (!user) return false;
	if(user.overrideDefaultAvatar){
		if(user.overrideAvatarOption.usePhotoUrl && user.photoURL){
			return true;
		}
	}else if (user.photoURL){
		return true;
	}
	return false;
}

interface FileElement {
	type: string;
	name: string;
	title: string;
	description?: string;
	isRequired: boolean;
	waitForUpload: boolean;
  }
  
/**
 * This function extracts the file contents from the specified surveyData object using the surveyTemplate,
 * Then uploads them to the specified cloud storage bucket,
 * Then modifies the Job|Opportunity object with the details of the uploaded files.
 * This function is implemented to overcome the issue with the file uploads in the survey, due to the size limit of the firestore document. 
 * @param data => Job or Opportunity object to extract the file contents from.
 * @param surveyDataField => Field name of the survey data object.
 * @param surveyTemplateField => Field name of the survey template.
 * @param surveyResultsField => Field name of the survey results object.
 * @returns => A promise of the Job or Opportunity object
 */  

async function extractSurveyFilesUploads(data: Job,surveyDataField:keyof Job,surveyTemplateField:keyof Job,surveyResultsField:keyof Job ):Promise<Job> {
		if (!data[surveyDataField]) {
		return(data);
		}

		let surveyTemplate;
		if (surveyTemplateField && data[surveyTemplateField] && typeof data[surveyTemplateField] === "string") {
		//@ts-ignore
			surveyTemplate=JSON.parse(data[surveyTemplateField])
		}

		const surveyPages=surveyTemplate.pages||[];
		if(!surveyPages || (surveyPages && surveyPages.length <= 0)) {
			return(data);
		}

		/** Filtering the survey elements that has files. The file elements looks like:
		 * {"type": "file","name": "question11","title": "Schedule of Values","isRequired": true,"waitForUpload": true}
		 */
		const fileElements:any[]=[];
		surveyPages.forEach((surveyPage:any)=>{
			const pageElements=surveyPage.elements;
			fileElements.push(...pageElements.filter((element:any)=>element.type === 'file'))
		})

		if (fileElements.length<=0){
			return(data);
		}

		//Extract the survey questions and their answers 
		const surveyData: { [key: string]: any } =JSON.parse(data[surveyDataField] as string);
		const fileQuestions: { [key: string]: any } ={};
		fileElements.forEach((fileElement:FileElement)=>{ 
			if (surveyData[fileElement.name] !== undefined){
				// Extract the survey answer from the survey data for a given question
				fileQuestions[fileElement.name]={};
				fileQuestions[fileElement.name]["files"]=surveyData[fileElement.name];
				// Extract the survey question title from the file element (ie, survey template)  
				fileQuestions[fileElement.name]["title"]=fileElement.title;
				// Clear the answer from the SurveyData (Otherwise the document will hit the firestore size limit) 
				surveyData[fileElement.name]=""
			}
		}) 

		// store the survey data (after clearing the file information) in the Job|Opportunity document
		// @ts-ignore
		data[surveyDataField] = JSON.stringify(surveyData);
		const bucketName=surveyFilesStorageBucket;

		// Upload each files to the cloud storage bucket
		const surveyFiles:any[]=[]
		for (const question in fileQuestions) {
			const fileData = fileQuestions[question]["files"];
			if(fileData && fileData?.length){
				const fileUploadPromises = fileData.map(async (file:any) => {
					const MAX_FILENAME_LENGTH = 1024;
					const filePath = "survey";
					let filename = `${filePath}/${generateRandomString(10)}_${file.name}`;
					if (filename.length > MAX_FILENAME_LENGTH) {
					const MAX_TOTAL_FILENAME_LENGTH = 1000;
					filename = `${generateRandomString(10)}_${file.name.substr(
						file.name.length - MAX_TOTAL_FILENAME_LENGTH + 10,
						MAX_TOTAL_FILENAME_LENGTH - 10 - 1
					)}`;
					}
					const publicUrl = await uploadToCloudStorage(bucketName, filename, file.content);
					return {
						filename: file.name,
						cloudStorageLink: publicUrl,
						surveyQuestionTitle: fileQuestions[question]["title"],
					};
				});
	
				surveyFiles.push(...await Promise.all(fileUploadPromises));
			}
		}

		// store the uploaded files info in the Job|Opportunity document
		// @ts-ignore
		data[surveyResultsField]=[...data[surveyResultsField],...surveyFiles]
		return data
}

async function uploadToCloudStorage(bucketName: string, fileName: string, fileContent: string) {
	const storage = firebase.app().storage(bucketName);
	
	const ref = storage.ref(fileName);
	const uploadTask = ref.putString(fileContent, 'data_url');
	try {
	  await uploadTask;
	  const downloadURL = await ref.getDownloadURL();
	  return downloadURL;
	} catch (error) {
	  console.error(error);
	}
  }

function generateRandomString(length: number): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export { currencyFormatter, showGeneratedAvatar, showPhotoAvatar, extractSurveyFilesUploads }