import { db } from '../../db';
import Business from '../../model/business';
import Price from '../../model/price';
import { actions as PriceActions } from '../../store/modules/price';
import { firestoreAction } from 'vuexfire';
import DBConsts from '@core-shared/db.consts';
import Quotation from '@/model/quotation';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const COLLECTION_NAME = DBConsts.Business.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allbusinesses";
const QUOTATION_COLLECTION_NAME = DBConsts.Quotation.COLLECTION_NAME;


const state = {
	allbusinesses: new Array<Business>()
}
const getters = {
	businesses(): Business[] {
		return [...state.allbusinesses];
	}
}
const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).orderBy("name", "asc").withConverter(Business.FirestoreDataConverter))
	}),
	addBusiness(context: unused, business: Business): Promise<firebase.firestore.DocumentReference<Business>> {
		return db.collection(COLLECTION_NAME).withConverter(Business.FirestoreDataConverter).add(business);
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	editBusiness({ rootGetters }: any, business: Business): Promise<void> {
		//Ensure all prices have the correct business name denormalised
		//TODO: Important: reinstate this
		PriceActions.editPrices({ rootGetters },
			rootGetters["price/prices"].filter((price: Price) => price.businessID == business.id && price.businessName !== business.name)
				.map((price: Price) => {
					return Object.assign(new Price(), price, { businessName: business.name, id: price.id })
				}), false)

		return db.collection(COLLECTION_NAME).doc(business.id).withConverter(Business.FirestoreDataConverter).set(business);
	},
	deleteBusiness(context: unused, business: Business): Promise<void> {
		return db.collection(COLLECTION_NAME).doc(business.id).delete();
	},
	addBusinesses(context: unused, businesses: Business[]): Promise<void> {
		let batch = db.batch();
		let count = 0;
		businesses.forEach(business => {
			if (count >= DBConsts.MAX_BATCH_OPPERATIONS) {
				batch.commit(); //todo: this is broken
				batch = db.batch();
				count = 0;
			}
			batch.set(db.collection(COLLECTION_NAME).withConverter(Business.FirestoreDataConverter).doc(), business)
			count++;
		})
		return batch.commit();
	},
	addQuotation(context: unused, quotation: Quotation): Promise<firebase.firestore.DocumentReference<Quotation>> {
		return db.collection(COLLECTION_NAME).doc(quotation.businessID).collection(QUOTATION_COLLECTION_NAME).withConverter(Quotation.FirestoreDataConverter).add(quotation);
	},

}

export default {
	state,
	actions,
	getters,
	namespaced: true,
	COLLECTION_NAME
}