import { db } from '../../db';
import Vendor from '../../model/vendor';
import { firestoreAction } from 'vuexfire';
import DBConsts from "@core-shared/db.consts";

export const COLLECTION_NAME = DBConsts.Vendor.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allVendors";

const state = {
	allVendors: new Array<Vendor>()
};

const getters = {
	vendors(): Vendor[] {
		return [...state.allVendors]
	}
};

const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collection(COLLECTION_NAME).withConverter(Vendor.FirestoreDataConverter))
	})
}

export default {
	state,
	getters,
	actions,
	namespaced: true,
	COLLECTION_NAME
}