import { convertCustomObjects, Timestamp } from '../db';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import Business from './business';

export interface PriceType {
	equipment: boolean;
	material: boolean;
	subcontractor: boolean;
}

export default class Price {
	["id"]!: string;

	archived: boolean = false;
	businessID: string = "";
	businessName: string = "";
	costCode: string = "";
	standardCostCodeId?: string = "";
	description: string = "";
	lastUpdated = Timestamp.now();
	notes: string = "";
	productVendor: string = "";
	supplierCode: string = "";
	title: string = "";
	type: PriceType = { equipment: false, material: false, subcontractor: false };
	unit: string = "";
	unitCost: number = 0;
	linkedInvoice: string = "";

	constructor(businessID?: string) {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
		this.businessID = businessID ? businessID : "";
	}
	static FirestoreDataConverter = {
		toFirestore(price: Price): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here

			//ensure that price.unitCost is a number/dollar value
			price.unitCost = Number(price.unitCost.toString().replace(/[^0-9.]+/g, ''));
			return convertCustomObjects(price);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Price {
			const data = snapshot.data(options) ?? {};
			return Object.assign(new Price(), data);
		}
	}
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	updateBusinessInfo(rootGetters: any): void {
		if (this.businessID && !this.businessName) {
			this.businessName = rootGetters["business/businesses"].find((business: Business) => business.id === this.businessID)?.name || "";
		} else if (this.businessName && !this.businessID) {
			this.businessID = rootGetters["business/businesses"].find((business: Business) => business.name === this.businessName)?.id || "";
		}
	}

	static toMenu(): { text: string; value: string; }[] {
		return Object.keys(new Price()).map(key => ({
			text: _.startCase(key),
			value: key
		}));
	}
}
