import { convertCustomObjects, Timestamp } from "@/db";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
export default class AppSettings {
	["id"]!: string;

	lastUpdated: firebase.firestore.Timestamp = Timestamp.now();

	operationsTemplatedFolderId: string= "";
	newJobSurveyTemplate:string ="";
	projectAdminSurveyTemplate:string ="";
	constructionManagerSurveyTemplate:string ="";
	newJobNotificationAddressees:string="";
	
	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(obj: AppSettings): firebase.firestore.DocumentData {
			return convertCustomObjects(obj);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): AppSettings {
			return Object.assign(new AppSettings(), snapshot.data(options) ?? {});
		}
	}
}