import { convertCustomObjects } from "@/db";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export default class Unit {
	["id"]!: string;

	title = "";
	description = "";

	constructor() {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
	}

	static FirestoreDataConverter = {
		toFirestore(unit: Unit): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return convertCustomObjects(unit);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Unit {
			const data = snapshot.data(options) ?? {};
			return Object.assign(new Unit(), data);
		}
	}
}