import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import User from './user';
import store from '@/store';
import { convertCustomObjects, Timestamp } from "@/db";


export default class ConstructionSchedule {
	["id"]!: string;
	handoverDate: string = "";
	comment: string = "";
	createdBy: User = store.state.storeUser
	created = Timestamp.now();
	job_id!: string;
	customer_id!: string;

	static FirestoreDataConverter = {
		toFirestore(constructionSchedule: ConstructionSchedule): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return convertCustomObjects(constructionSchedule);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): ConstructionSchedule {
			const data = snapshot.data(options)!;
			return Object.assign(new ConstructionSchedule(), data);
		}
	}
}