import { convertCustomObjects, Timestamp } from '../db';
import _ from 'lodash';
import Price, { PriceType } from './price';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export class QuotationPrice {
	costCode: string = "";
	title: string = "";
	quantity: string | number = 0;
	type: PriceType = { equipment: false, material: false, subcontractor: false };
	unit: string = "";
	unitCost: number = 0;
	supplierCode: string = "";
}

export default class Quotation {
	["id"]!: string;

	businessID: string = "";
	businessName: string = "";
	quotationReference: string = "";
	quotationData = new Array<QuotationPrice>();
	linkedInvoice: string = "";

	constructor(businessID?: string) {
		Object.defineProperty(this, 'id', {
			enumerable: false,
			writable: true,
			value: ""
		});
		this.businessID = businessID ? businessID : "";
	}

	static FirestoreDataConverter = {
		toFirestore(quotation: Quotation): firebase.firestore.DocumentData {
			//Enforce business rules and type rules here
			return convertCustomObjects(quotation);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): Quotation {
			const data = snapshot.data(options) ?? {};
			return Object.assign(new Quotation(), data);
		}
	}

}
