import { db, Timestamp } from '../../db';
import Price from '../../model/price';
import Business from '../../store/modules/business';
//import Fuse from 'fuse.js';
import moment from 'moment';
import { firestoreAction } from 'vuexfire';
import _ from 'lodash';
import DBConsts from '@core-shared/db.consts';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const COLLECTION_NAME = DBConsts.Price.COLLECTION_NAME;
const STOREVARIABLE_NAME = "allprices";
const HISTORY_COLLECTION_NAME = "history";

const state = {
	allprices: new Array<Price>()
};

const getters = {
	prices(): Price[] {
		return [...state.allprices];
	},
	pricesForBusiness: (state: { allprices: Price[]; }) => (businessID: string): Price[] => {
		const prices = state.allprices.filter((price: Price) => price.businessID === businessID);
		return prices;
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function ProcessPrice(price: Price, rootGetters: any, updateBusinessInfo: boolean = true) {
	// Perform checks and cleanup before performing operations
	// The converter handles data integrity
	//TODO: need to check unit when processing @Critical 
	if (updateBusinessInfo) {
		price.updateBusinessInfo(rootGetters);//TODO: this is going to be expensive opperation, does this need to be here?
	}
	if (price.lastUpdated) {
		if (typeof price.lastUpdated === "string") {
			price.lastUpdated = Timestamp.fromDate(moment(price.lastUpdated).toDate())
			// @ts-ignore
		} else if (typeof price.lastUpdated.getUTCMilliseconds === "function") { //This will be a Date()
			// @ts-ignore
			price.lastUpdated = Timestamp.fromDate(price.lastUpdated)
			// @ts-ignore
		} else if (typeof price.lastUpdated.isMoment === "function") { // This will be a moment object
			price.lastUpdated = Timestamp.fromDate(price.lastUpdated.toDate());
		}
	} else {
		price.lastUpdated = Timestamp.now();
	}
}

function getExistingPrice(price: Price): Price | null {
	const pricesOfBusiness = state.allprices.filter((priceItem: Price) => priceItem.businessID === price.businessID);
	let existingPrice;
	if (price.supplierCode) {
		existingPrice = pricesOfBusiness.find((priceItem: Price) => priceItem.supplierCode === price.supplierCode);
	} else {
		existingPrice = pricesOfBusiness.find((priceItem: Price) => priceItem.title.trim().toLowerCase() === price.title.trim().toLowerCase());
	}
	return existingPrice || null;
}

export const actions = {
	bindRef: firestoreAction(context => {
		return context.bindFirestoreRef(STOREVARIABLE_NAME, db.collectionGroup(COLLECTION_NAME).orderBy("lastUpdated", "desc").limit(50).withConverter(Price.FirestoreDataConverter))
	}),
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	addPrice({ rootGetters }: any, price: Price): Promise<firebase.firestore.DocumentReference<Price>> {
		const existingPrice = getExistingPrice(price)
		if (existingPrice) {
			price.id = existingPrice.id;
			actions.editPrice({ rootGetters }, price);
			return Promise.resolve(db.collection(Business.COLLECTION_NAME).doc(price.businessID).collection(COLLECTION_NAME).withConverter(Price.FirestoreDataConverter).doc(price.id));
		}
		ProcessPrice(price, rootGetters);

		return db.collection(Business.COLLECTION_NAME).doc(price.businessID).collection(COLLECTION_NAME).withConverter(Price.FirestoreDataConverter).add(price);

	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	editPrice({ rootGetters }: any, price: Price): Promise<void> {
		ProcessPrice(price, rootGetters);


		//if the price has been updated we will archive the old price for historical records
		const storePrice = { ...state.allprices.find((x: Price) => x.id == price.id) } as Price;

		let archive: boolean = false;
		if (storePrice) {
			if (storePrice.unitCost !== price.unitCost) {
				archive = true;
			}

			if (archive) {
				//@ts-ignore
				delete storePrice["id"];
				storePrice.archived = true;
				db.collection(Business.COLLECTION_NAME).doc(price.businessID).collection(COLLECTION_NAME).doc(price.id).collection(HISTORY_COLLECTION_NAME).withConverter(Price.FirestoreDataConverter).add(storePrice)
				if (storePrice.lastUpdated == price.lastUpdated) {
					price.lastUpdated = Timestamp.now();
				}
			}
		}

		return db.collection(Business.COLLECTION_NAME).doc(price.businessID).collection(COLLECTION_NAME).withConverter(Price.FirestoreDataConverter).doc(price.id).set(price);
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
	addPrices({ rootGetters }: any, prices: Price[], updateBusinessInfo: boolean = true): Promise<void> {
		let batch = db.batch();
		let count = 0;
		_.uniqWith(prices, (arrVal, othVal) =>
			(arrVal.businessID == othVal.businessID && (!(_.isEmpty(arrVal.supplierCode) && _.isEmpty(othVal.supplierCode)) && arrVal.supplierCode?.toLowerCase().trim() === othVal.supplierCode?.toLowerCase().trim()) || (!(_.isEmpty(arrVal.title) && _.isEmpty(othVal.title)) && arrVal.title?.toLowerCase().trim() == othVal.title?.toLowerCase().trim()))
		).forEach(price => {
			if (count >= DBConsts.MAX_BATCH_OPPERATIONS) {
				batch.commit(); // TODO: probably should be doing error handling here, this is broken we need to await promises
				// BUG: TODO: this is wrong, we are losing data to promises here
				batch = db.batch();
				count = 0;
			}
			ProcessPrice(price, rootGetters, updateBusinessInfo);

			const storePrices = state.allprices.filter((x: Price) => x.businessID == price.businessID)
			let storePrice = storePrices.find((x: Price) => !_.isEmpty(x.supplierCode) && x.supplierCode?.toLowerCase().trim() === price.supplierCode?.toLowerCase().trim())
			if (storePrice === undefined) {
				const foundStorePrices = storePrices.filter((x: Price) => !_.isEmpty(x.title) && x.title?.toLowerCase().trim() === price.title?.toLowerCase().trim())
				storePrice = foundStorePrices[0]
				// if (foundStorePrices.length > 1) {
				//     const options = {
				//         includeScore: true,
				//         keys: ['title']
				//     }

				//     const fuse = new Fuse(storePrices, options);
				//     const result = fuse.search(price.title);
				//     storePrice = result[1].item
				// }
			}

			if (storePrice !== undefined) {
				const oldPrice = { ...storePrice }
				const id = storePrice.id;
				//@ts-ignore
				delete oldPrice.id;
				db.collection(Business.COLLECTION_NAME).doc(oldPrice.businessID).collection(COLLECTION_NAME).doc(id).collection(HISTORY_COLLECTION_NAME).withConverter(Price.FirestoreDataConverter).add(Object.assign(new Price(), oldPrice));
				const priceToUpdate = _.mergeWith(oldPrice, price, (objectValue, otherValue) => {
					if (!_.isBoolean(objectValue) && _.isEmpty(otherValue)) {
						return objectValue;
					} else {
						return otherValue;
					}
				});

				if (!priceToUpdate.type.equipment && !priceToUpdate.type.material && !priceToUpdate.type.subcontractor) {
					priceToUpdate.type = oldPrice.type
				}

				db.collection(Business.COLLECTION_NAME).doc(oldPrice.businessID).collection(COLLECTION_NAME).withConverter(Price.FirestoreDataConverter).doc(id).set(priceToUpdate);
			} else {
				batch.set(db.collection(Business.COLLECTION_NAME).doc(price.businessID).collection(COLLECTION_NAME).withConverter(Price.FirestoreDataConverter).doc(), price)
			}
			count++;
		})
		return batch.commit();
	},
	// addPricesFindBusinessNameAndUnit({ getters }: any, prices: Price[]) {
	//     const businessMap = new Map<string, string>();
	//     const unitMap = new Map<string, string>();
	//     prices.sort((a, b) => (a.businessName > b.businessName ? 1 : -1)).forEach(price => {
	//         if (!businessMap.has(price.businessName)) {
	//             const options = {
	//                 isCaseSensitive: false,
	//                 shouldSort: true,
	//                 keys: [
	//                     "name",
	//                 ]
	//             };
	//             const fuse = new Fuse(getters.businesses, options);
	//             const res = fuse.search(price.unit)
	//             businessMap.set(price.businessName, (first(res)?.item as Business)?.name || "")
	//         }
	//         if (!unitMap.has(price.unit)) {
	//             unitMap.set(price.unit, getters.units.find((unit: Unit) => unit.title === price.unit) || "")
	//         }

	//         price.businessID = businessMap.get(price.businessName) || "";
	//         price.unit = unitMap.get(price.unit) || "";
	//     })

	//     return this.addPrices({ getters }, prices, false);
	// },
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
	editPrices({ rootGetters }: any, prices: Price[], updateBusinessInfo: boolean = true) {
		let batch = db.batch();
		let count = 0;
		prices.forEach((price: Price) => {
			if (count >= DBConsts.MAX_BATCH_OPPERATIONS) {
				batch.commit(); // TODO: probably should be doing error handling here
				batch = db.batch();
				count = 0;
			}

			ProcessPrice(price, rootGetters, updateBusinessInfo);
			batch.set(db.collection(Business.COLLECTION_NAME).doc(price.businessID).collection(COLLECTION_NAME).withConverter(Price.FirestoreDataConverter).doc(price.id), price)
			count++;
		})
		return batch.commit();
	},
	deletePrice(_: unused, price: Price): Promise<void> {
		return db.collection(Business.COLLECTION_NAME).doc(price.businessID).collection(COLLECTION_NAME).doc(price.id).delete();
	}
}

export default {
	state,
	actions,
	getters,
	namespaced: true,
	COLLECTION_NAME
}

