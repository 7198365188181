import { convertCustomObjects } from '@/db';
import store from '@/store';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import IInvoiceFilter from '@core-shared/interfaces/IInvoiceFilter';
import IJobFilter from '@core-shared/interfaces/IJobFilter';
import IUser from "@core-shared/interfaces/IUser";
import OverrideAvatarOption from './overrideAvatarOption';
import Role, { ROLE_IDS } from './role';
import StoredUser from "@core-shared/interfaces/IStoredUser";

const pwdLowercase = "abcdefghijklmnopqrstuvwxyz";
const pwdUppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const pwdNumbers = "0123456789";
// Should contain at least a capital letter
// Should contain at least a small letter
// Should contain at least a number
// Should contain at least a special character
// And minimum length 8

const pwdLen = 10;

export enum USER_SOURCES {
	google = "google.com",
	password = "password",
}

export default class User implements IUser {
	id: string = "";
	displayName: string = "";
	email: string = "";
	photoURL: string = "";
	permissions: Array<string> = ['default']
	procore_id?: number;
	source: USER_SOURCES = USER_SOURCES.google;
	multipaneSize: string = "50";
	isVerticalAligned = true;
	invoiceFilter: IInvoiceFilter = {
		invoiceCustomFilter: '',
		invoiceCustomStageFilter: 0,
		includeDeletedInvoices: false,
	};
	jobsFilter: IJobFilter = {
		allstages: false,
		stage: "",
		supervisor: undefined,
		projectManager: undefined,
		customer: undefined,
		minimal: true,
		site: undefined,
		department: undefined,
		incompleteSecondaryStatus: false,
	};
	overrideDefaultAvatar: boolean = false;
	overrideAvatarOption: OverrideAvatarOption = new OverrideAvatarOption();
	idToken?: string;

	constructor(user?: firebase.User, partial?: Partial<User>) {
		this.id = user?.uid || "";
		this.displayName = user?.displayName || "";
		this.email = user?.email || "";
		this.photoURL = user?.photoURL || "";

		Object.assign(this, partial);
	}

	get refFormat(): StoredUser {
		return { id: this.id, displayName: this.displayName, email: this.email }
	}

	get roles(): Array<Role> {
		return store.getters['role/getRoles'](this.permissions)
	}

	get allowedRoutes(): string[] {
		return [... new Set(this.roles.map((role) => role.routes).flat())];
	}

	get isAdmin(): boolean {
		return this.roles.some((role) => role.id === "admin");
	}

	addRole(role: Role): void {
		this.permissions.push(role.id);
	}

	removeRole(role: Role): void {
		this.permissions = this.permissions.filter((p) => p !== role.id);
		if (!this.permissions.length) {
			this.permissions.push(ROLE_IDS.default)
		}
	}

	isRole(rid: ROLE_IDS): boolean {
		return this.roles.some((role) => role.id === rid);
	}

	isRouteAllowed(route: string): boolean {
		return this.allowedRoutes.some((r) => r === "all" || r === route);
	}

	resetPassword(): Promise<void> {
		return firebase.auth().sendPasswordResetEmail(this.email);
	}

	static FirestoreDataConverter = {
		toFirestore(user: User): firebase.firestore.DocumentData {
			return convertCustomObjects(user);
		},
		fromFirestore(
			snapshot: firebase.firestore.QueryDocumentSnapshot,
			options: firebase.firestore.SnapshotOptions
		): User {
			const data = snapshot.data(options) ?? {};
			const user = new User(undefined, data);
			return user;
		}
	}
}

export class NewUser extends User {
	password: string = "";

	constructor() {
		super();
		this.randomizePassword();
	}

	private getRandomIntInclusive(min: number, max: number) {
		const randomBuffer = new Uint32Array(1);

		window.crypto.getRandomValues(randomBuffer);

		const randomNumber = randomBuffer[0] / (0xffffffff + 1);

		min = Math.ceil(min);
		max = Math.floor(max - 1);
		return Math.floor(randomNumber * (max - min + 1)) + min;
	}

	private shuffleArray(array: string[]) {
		let currentIndex = array.length, randomIndex;

		// While there remain elements to shuffle...
		while (currentIndex != 0) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;
			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [
				array[randomIndex], array[currentIndex]];
		}

		return array;
	}

	randomizePassword(): void {
		let count = pwdLen;
		const lowercaseChars = Array(count -= 6).fill('').map((c) => {
			const ind = this.getRandomIntInclusive(0, pwdLowercase.length);
			return pwdLowercase[ind];
		});
		const capitalChars = Array(count -= 1).fill('').map((c) => {
			const ind = this.getRandomIntInclusive(0, pwdUppercase.length);
			return pwdUppercase[ind];
		});
		const numChars = Array(count).fill('').map((c) => {
			const ind = this.getRandomIntInclusive(0, pwdNumbers.length);
			return pwdNumbers[ind];
		});

		const pwdChars = [...lowercaseChars, ...capitalChars, ...numChars];

		this.password = this.shuffleArray(pwdChars).join('');
	}
}